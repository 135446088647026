const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://nuyii8b8oc.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://xi9wylfaj1.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://sjc02ugwve.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.1',
    HOSTNAME: 'https://locations.debmarine.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.debmarine.forwoodsafety.com',
    WEBSOCKET: 'wss://tlqypz0zw1.execute-api.us-west-2.amazonaws.com/prod'
  },
};

export default config;